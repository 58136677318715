:root {
  --dark-color: rgb(0, 28, 48);
  --light-color: rgb(23, 107, 135);
  --lighter-color: rgb(100, 204, 197);
  --lightest-color: rgb(218, 255, 251);

  --fanduel-color: rgb(31, 55, 91);
  --draftkings-color: rgb(10, 50, 49);
  --betmgm-color: rgb(41, 42, 46);
}

/* Global classes */
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;

}

.about {
  color: white;
}

.app-title {
  margin-top: 10px;
  color: var(--lightest-color);
}

.betslip-toggle {
  background-color: grey;
  padding: 10px 20px;
  margin-top: 15px;
}

.submit {
  margin: 15px;
}

/* Login Styling */
.login-container {
  max-width: 400px;
  margin: auto;
}

/* Main Page Content */ 
.content {
  max-width: 65%;
  margin: 0 auto;
}

.list-container {
  max-width: 15%;
  margin: auto;
}

/* EventContainer */

.events-container {
  
}

.event-card {
  margin: 0 auto 20px;
  padding: 2px;
  border: solid 3px var(--light-color);
  border-radius: 8px;
  background-color: white;
  color: var(--dark-color);
}

.events-header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.events-header div {
  padding: 0 10px;
}

.events-header-btn-container {
  display: flex;
  
}

/* EventContainer */

.event-team-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px; */
}

.event-card-middle-row {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: var(--dark-color);
} 

.event-card-middle-row h3 {
  font-size: 14px;
}



.event-middle-row-text {
  margin: 0px;
}

.event-at-symbol {
  position: relative;
  left: 125px;
}

.event-date-container {
  position: relative;
  left: 40%;
}

.event-team-odds-container {
  display: flex;
}

/* TeamInfo */
.event-team-info {
  display: flex;
  
  
  align-items: center;
}

.event-team-info h3 {
  margin: 0;
}

.event-team-name {
  
}

.team-logo-container {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.team-logo {
  max-width: 60px;
  height: 60px;
}



/* OutcomeCard */
.outcome-container {
  border-radius: 8px;
  margin: 5px 5px;
  background-color: var(--dark-color);
  
}
.event-team-maker {
  display: flex;
  align-items: center;
  padding: 5px;
  
  border-radius: 5px;
  border: solid 1px lightgray;
  background-color: lightgray;
  cursor: pointer;
}

.event-team-maker.selected, .event-team-maker:hover {
  border: solid 1px var(--lighter-color);
  background-color: var(--lighter-color)
}

.odds-text-container {
  text-align: center;
  padding: 2px;
}

.odds-text {
  font-weight: bold;
  margin: 0;
  min-width: 54px;
  text-align: center;
  /* display: inline; */
}

.odds-text.point {
  color: green;
}

.vote-count-container {
  color:var(--lightest-color);
  font-size: .6rem;
  font-weight: bold;
}

/* BookmakerLogo */
.bookmaker-logo-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.bookmaker-logo {
  max-width: 45px;
  height: 45px;
}

.logo-fanduel {
  background: var(--fanduel-color);
}

.logo-draftkings {
  background: var(--draftkings-color);
}

.logo-betmgm {
  background: var(--betmgm-color);
}



/* Selector Bar Classes */

.selector-bar{
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
}

.option {
  border: solid 2px var(--light-color);
  border-radius: 3px;
  font-weight: bold;
  background-color: white;
  color: var(--dark-color);
  min-width: 150px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px;
}

.option.selected {
  border: solid 2px var(--light-color);
  background-color: var(--lighter-color);
  color: var(--dark-color);
}

/* Betslip */
.betslip-container {
  border: 1px solid black;
  background-color: white;
  border-radius: 6px;
  height: 100vh;
  width: 17%;
  position: fixed;
  right: 0;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.betslip-container::-webkit-scrollbar {
  display: none;
}

.betslip-header {
  border-bottom: 1px solid black;
  margin: 0;
}

.betslip-bookmaker-header {
  display: flex;
  border-radius: 5px;
}

.betslip-bookmaker-header.fanduel {
  background-color: var(--fanduel-color);
}

.betslip-bookmaker-header.draftkings {
  background-color: var(--draftkings-color);
}

.betslip-bookmaker-header.betmgm {
  background-color: var(--betmgm-color);
}

.parlay-switch-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parlay-switch-container p {
  margin: 0;
  padding: 0 10px;
  color: white;
}



/* BetslipEntry */
.betslip-entry-bookmaker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.betslip-entry-event-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.betslip-entry-event-container h2{
  font-size: 1.2rem;
  margin: 0 10px;
}

.betslip-entry-event-container .event-team-info{
  flex-direction: column;
  width: 150px;
}

.betslip-entry-event-container .event-team-info h3{
  font-size: 0.9rem;
}

.betslip-entry-delete-button {
  cursor: pointer;
  padding: auto;
}


/* Media Queries */
@media (max-width: 1500px) {
  .content {
    max-width: 95%;
  }

  .betslip-container {
    width: 50%;
    top: 20%;
    right: 25%;
    z-index: 1;
  }
}

@media (max-width: 1050px) {
  .event-date-container {
    left: 30%;
  }
}

@media (max-width: 825px) {
  .event-middle-row-text {
    font-size: .75rem;
  }

  .event-at-symbol {
    left: 70px;
  }

  .event-date-container {
    left: 150px;
  }

  .betslip-container {
    width: 65%;
    right: 17.5%
  }
}
  

@media (max-width: 525px) {
  .event-card h3 {
    font-size: .7rem;
  }

  .team-logo {
    max-width: 40px;
    height: 40px;
  }

  .outcome-container {
    margin: 3px 3px;
  }

  .vote-count {
    font-size: .5rem;
  }

  .bookmaker-logo {
    max-width: 30px;
    height: 30px;
  }

  .event-team-maker {
    padding: 2px;
  }
  
  .odds-text-container {
    padding: 0px;
  }

  .odds-text-container p {
    font-size: .65rem;
  }

  .odds-text {
    min-width: 35px;
  }

  .event-at-symbol {
    left: 45px;
  }

  .event-date-container {
    left: 150px;
  }
  

  .option {
    font-size: .7rem;
    min-width: 100px;
    cursor: pointer;
    padding: 2px 5px;
    margin: 5px;
  }

  .betslip-entry-bookmaker-container {
    font-size: .8rem;
  }

  .betslip-wager-amount-container {}

  .betslip-wager-amount-input {
    max-width: 100px;
  }
}

/* Welcome/My Bets page */
.user-bets-container {
  color: var(--lightest-color);
  padding-bottom: 5%;
}

.user-bet-cards-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.bet-card {
  border: solid 3px var(--light-color);
  border-radius: 8px;
  background-color: white;
  color: var(--dark-color);
  font-weight: 500;
  min-width: 350px;
  font-size: 0.75rem;
}

.bet-card > div {
  padding: 1.5%;
}

.bet-card p {
  margin: 0;
}

.bet-card-header {
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: var(--dark-color);
  font-size: 1rem;
  
  border-radius: 4px 4px 0 0;
}

.bet-card-header-selection-container {
  align-items: flex-start;
}

.bet-card-header-selection {
  color: var(--lighter-color);
}

.bet-card-header-market {
  /* color: var(--lighter-color); */
  font-size: .8rem;
  font-weight: 400;
}

.bet-card-event-details-container {
  font-size: 0.75rem;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.bet-card-event-details {
  
}

.bet-card-wager-details {
  justify-content: space-around;
}

/* .bet-card-wager-container {
  align-items: flex-start;
} */

.bet-card-parlay-leg-container {
  /* border-bottom: 1px solid black; */
}

.bet-card-leg-header {
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
}

.bet-card-leg-market {
  font-size: 0.75rem;
}

.bet-card-leg-selection-container {
  align-items: flex-start;
}

.bet-card-event-details-container { 
  font-weight: 400;
}

.bet-card-id-container {
  margin-top: auto;
  background-color: lightgray;
  font-size: 0.6rem;
  border-top: 1px solid black;
}

.auth-form p {
  margin: 0;
  padding: 2% 0;
}